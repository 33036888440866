<template>
  <v-card class="dialog-order-receipt-card">
    <iframe
      v-if="htmlReceipt"
      ref="iframe"
      id="digital-receipt"
      :srcdoc="htmlReceipt"
      style="width: 100%; height: calc(100% - 60px); border: none;"
    ></iframe>
    <v-alert type="warning" v-else-if="htmlReceipt === false">
      {{ $t("order.noDigitalReceipt") }}
    </v-alert>
    <v-card-actions class="fixed-actions">
      <v-spacer></v-spacer>
      <v-btn
        class="rounded-pill ma-1"
        active-class="primary"
        color="primary"
        depressed
        outlined
        @click="$emit('submit')"
      >
        Chiudi
      </v-btn>
      <v-btn
        class="rounded-pill ma-1"
        active-class="primary"
        color="primary"
        depressed
        @click="printDigitalReceipt('digital-receipt')"
      >
        Stampa
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.dialog-order-receipt-card {
  height: calc(100vh - 50px);
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: calc(100vh - 200px);
  }
  .receipt-content {
    overflow-y: auto;
  }
  .fixed-actions {
    position: sticky;
    bottom: 0;
    background: white; /* Match with card background to hide underlying content */
    z-index: 1; /* Ensure it's above the scrolling content */
  }
}
</style>
<script>
import downloadDocumentMixin from "~/mixins/downloadDocument";
import CustomService from "@/service/customService";

export default {
  name: "DialogOrderReceipt",
  mixins: [downloadDocumentMixin],
  props: ["orderId"],
  data() {
    return {
      showingDigitalReceipt: false,
      htmlReceipt: null
    };
  },
  methods: {
    async showDigitalReceipt(orderId) {
      try {
        const res = await CustomService.getDigitalReceipt(orderId);
        if (res.response.status === 0) {
          this.showingDigitalReceipt = true;
          this.htmlReceipt = atob(res.data.value);
        } else {
          this.htmlReceipt = false;
        }
      } catch (err) {
        console.log(err);
        this.htmlReceipt = false;
      }
    },
    printDigitalReceipt() {
      const iframe = document.getElementById("digital-receipt");
      console.log(iframe);
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.defaultView.print();
    }
  },
  mounted() {
    this.showDigitalReceipt(this.orderId);
  }
};
</script>
