<template>
  <div class="orders-table">
    <v-row no-gutters class="text-body-2 mt-5 mb-2 headers">
      <v-col cols="4" sm="2">
        N° Ordine
      </v-col>
      <v-col cols="4" sm="3" md="2">
        Stato
      </v-col>
      <v-col cols="5" sm="4" md="3" lg="5" class="hidden-xs-only">
        Consegna/Ritiro
      </v-col>
      <v-col cols="4" sm="3" md="2" lg="1" class="text-right text-md-center">
        Totale
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        :aria-label="`Vai all'ordine - ${order.orderId}`"
        :title="`Vai all'ordine - ${order.orderId}`"
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
        :class="{ 'highlighted-order': order.orderStatusId === 3 }"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="4" sm="2">
            <span class="order-id primary--text font-weight-600"
              >#{{ order.orderId }}</span
            >
          </v-col>
          <v-col cols="4" sm="3" md="2">
            <OrderStatusBadge v-bind:order="order" :disabled="true" />
          </v-col>
          <v-col cols="5" sm="4" md="3" lg="5" class="hidden-xs-only">
            <span class="text-capitalize d-flex flex-column">
              <span class="d-block d-lg-none"> </span>
              <!-- <img
                class="address-image d-none d-lg-inline"
                :src="
                  $t(
                    'navbar.service.' +
                      order.shippingAddress.deliveryServiceId +
                      '.icon',
                    { color: 'color' }
                  )
                "
              /> -->
              <div class="d-flex flex-row">
                <div class="d-flex pr-2">
                  <v-icon
                    v-if="
                      order.shippingAddress &&
                        order.shippingAddress.deliveryServiceId
                    "
                    x-large
                    color="primary"
                    class="h-100 w-100"
                    >${{
                      $t(
                        `navbar.service.${order.shippingAddress.deliveryServiceId}.fontIcon`
                      )
                    }}</v-icon
                  >
                </div>
                <div class="d-flex flex-column">
                  <span class="font-weight-semibold">
                    {{
                      $t(
                        "navbar.address." +
                          order.shippingAddress.addressTypeId +
                          ".format",
                        order.shippingAddress
                      )
                    }}
                  </span>
                  <span>
                    {{
                      $dayjs(
                        order.timeslot.date + " " + order.timeslot.beginTime,
                        "YYYY-MM-DD hh:mm:ss A"
                      ).format("D MMMM | HH:mm")
                    }}
                  </span>
                </div>
              </div>
            </span>
          </v-col>
          <v-col
            cols="4"
            sm="3"
            md="2"
            lg="1"
            class="text-right text-md-center"
          >
            <OrderGrossTotal :item="order"></OrderGrossTotal>
          </v-col>
          <v-col
            cols="2"
            md="3"
            lg="2"
            class="actions-order d-none d-md-flex justify-end align-center hidden-sm-and-down "
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click.stop.prevent="showDigitalReceipt(order.orderId)"
                  depressed
                  icon
                  large
                  outlined
                  class="rounded-circle pa-1"
                  v-if="
                    order.shippingAddress &&
                      order.shippingAddress.deliveryServiceId &&
                      order.orderStatusId == 8
                  "
                >
                  <v-icon size="23" class="pa-1 ma-1">$invoice</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.button.receipt") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  outlined
                  @click.stop.prevent="editOrder(order.orderId)"
                  class="primary--text ml-3"
                  v-if="order.isEditable"
                >
                  <v-icon small>$edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.button.edit") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="primary--text ml-3"
                  @click.stop.prevent="deleteOrder(order.orderId)"
                  color="default"
                  v-if="order.isDeletable"
                >
                  <v-icon small>$trashXmark</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.button.delete") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                  color="primary"
                  class="ml-3"
                  fab
                  small
                  depressed
                  outlined
                  @click.stop.prevent="addAllToCart(order.orderId)"
                  :aria-label="$t('orders.button.reorder')"
                >
                  <v-icon small>$cartArrowDown</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.button.reorder") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.orders-table {
  .headers {
    font-weight: 600;
  }
  .v-list {
    background-color: transparent;
    .v-list-item {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
  .highlighted-order {
    border: 1px solid var(--v-primary-base) !important;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import DialogOrderReceipt from "./DialogOrderReceipt.vue";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import login from "~/mixins/login";

// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  mixins: [login],
  props: ["orders"],
  components: {
    OrderStatusBadge,
    OrderGrossTotal
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    async showDigitalReceipt(orderId) {
      this.$dialog.show(DialogOrderReceipt, {
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        orderId: orderId
      });
    },
    delivered(order) {
      return (
        order.orderStatusId == 6 ||
        order.orderStatusId == 8 ||
        order.orderStatusId == 18
      );
    },
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    async editOrder(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.confirmOrderEdit", {
          orderId: orderId
        }),
        width: 575
      });
      if (res) {
        this.$emit("editOrder", orderId);
      }
    },
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$emit("addAllToCart", orderId);
          }
        }
      }
    }
  },
  mounted() {}
};
</script>
