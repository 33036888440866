<template functional>
  <span class="gross-total font-weight-semibold">
    {{
      parent.$n(
        props.item.deliveredGrossTotal || props.item.grossTotal,
        "currency"
      )
    }}
  </span>
</template>
<script>
export default {
  name: "props.orderGrossTotal",
  props: {
    item: { type: Object, required: true }
  }
};
</script>
